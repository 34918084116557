import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import Button from "@prism/button";
import {addSuggestedDamage} from "../../../actions/SuggestedDamageActions";
import {addDamage} from "../../../actions/conditionActions";
import {showDamageSuccessMessage} from "../../../actions/globalDisplayActions";
import {addSuggestedDamageImage} from "../../../actions/damageImageActions";
import {clearSelectedFlatCarPanel} from "../../../actions/flatCarActions";
import * as pricingUtils from "./PricingUtils";
import SlidePanel from "../../SlidePanel";
import Pricing from "../Pricing";

const AddSuggestedDamageButton = (props) => {
    const {
        account,
        addDamage,
        addSuggestedDamage,
        addSuggestedDamageImage,
        consignment,
        suggestedDamage,
        showDamageSuccessMessage,
        workOrderInfo,
        clearSelectedFlatCarPanel,
        panelSuggestedDamages,
        setApiLoading,
        isCRSO,
    } = props;

    const [showPricing, setShowPricing] = useState(false);
    const [pricingTypesList, setPricingTypesList] = useState([]);
    const [dmg, setDmg] = useState({});

    useEffect(() => {
        if (pricingTypesList.length !== 0) {
            setShowPricing(true)
        }
    }, [pricingTypesList]);

    function getDamageKey() {
        return suggestedDamage.newDamageKey ? suggestedDamage.newDamageKey : suggestedDamage.artCodes.damageKey;
    }

    const handleAddSuggestedDamage = async () => {
        setApiLoading(true)
        const chargeableOptions = await pricingUtils.fetchChargeableOptions(suggestedDamage, account, consignment);
        const actionOptions = await pricingUtils.fetchActions(suggestedDamage, account, consignment, workOrderInfo);
        setApiLoading(false)
        let damage = await pricingUtils.buildDamageFromSuggestedDamage(suggestedDamage, getDamageKey(), chargeableOptions, actionOptions);
        setDmg(damage)
        if (isCRSO) {
            let estimateResponse;
            try {
                estimateResponse = await getEstimate(damage);
            } catch (e) {
                return;
            }
            const {overlays, pricingTypes} = await pricingUtils.getPricingTypesAndOverlays(estimateResponse)
            if (!!estimateResponse && Object.keys(estimateResponse).length !== 0) {
                setPricingTypesList(pricingTypes);
                damage.pricePlanIds = await pricingUtils.getPricePlans(estimateResponse);
                const callPricing = overlays.length !== 0 && pricingTypes?.length === 0;
                if (callPricing) {
                    const pricingResponse = await getPricing(damage);
                    const finalPricing = await getFinalPricing(pricingResponse);
                    if (await pricingUtils.pricingIsValid(finalPricing)) {
                        await handleAddDamage(damage, finalPricing);
                    }
                }
            } else {
                console.log("Empty estimate response")
                // TODO error handling will happen in US1430935. Please remove this comment and log statement when that card is worked
            }
        } else {
            addSuggestedDamage({...suggestedDamage, isEdited: props.isEdited});
            addDamage(damage);
            if (!!suggestedDamage.path) {
                addSuggestedDamageImage(getDamageKey(), suggestedDamage.path)
            }
            showDamageSuccessMessage();
            if (!panelSuggestedDamages()) clearSelectedFlatCarPanel();
        }
    }

    const getEstimate = async (damage) => {
        setApiLoading(true);
        let estimateResponse;
        try {
            estimateResponse = await pricingUtils.fetchEstimate(props, damage);
            setApiLoading(false);
        } catch (e) {
            setApiLoading(false);
            console.log("Estimate call failed")
            // TODO error handling will happen in US1430935. Please remove this comment and log statement when that card is worked
        }
        return estimateResponse;
    }

    const getPricing = async (damage) => {
        let response;
        try {
            setApiLoading(true);
            response = await pricingUtils.fetchPricing(props, damage);
            setApiLoading(false);
        } catch (e) {
            setApiLoading(false);
            console.log("Pricing call failed")
            // TODO error handling will happen in US1430935. Please remove this comment and log statement when that card is worked
            return e;
        }
        return response;
    }

    async function getFinalPricing(pricingResponse) {
        let finalPricing = {};
        if (Array.isArray(pricingResponse) && !!pricingResponse.length) {
            finalPricing = await pricingUtils.getFinalPricing(pricingResponse)
        }
        return finalPricing;
    }

    async function handleAddDamage(damage, finalPricing) {
        addSuggestedDamage({...suggestedDamage, isEdited: props.isEdited});
        addDamage(await pricingUtils.mapDamagePricing(damage, finalPricing))
    }

    const handlePricingUpdate = async (damage) => {
        setDmg(prevDamage => ({
            ...prevDamage,
            pricePlanIds: damage.pricePlanIds
        }))
        const pricingResponse = await getPricing(damage);
        const finalPricing = await getFinalPricing(pricingResponse);
        if (await pricingUtils.pricingIsValid(finalPricing)) {
            await handleAddDamage(damage, finalPricing);
        }
    };

    const cancelPricingOverlay = async () => {
        setDmg(prevDamage => ({
            ...prevDamage,
            repairLaborCost: null,
            repairLaborHours: null,
            paintLaborCost: null,
            paintLaborHours: null,
            partLaborCost: null,
            partLaborHours: null,
            partCost: null,
            finalPartCost: null,
            pricePlanIds: []
        }))
        setShowPricing(false);
    };

    const hidePricingOverlay = async () => {
        setShowPricing(false);
    };

    const isDisabled = useMemo(() => {
        return !suggestedDamage.artCodes.damage ||
            !suggestedDamage.artCodes.damageCode ||
            !suggestedDamage.artCodes.severity ||
            !suggestedDamage.artCodes.severityCode;
    }, [suggestedDamage]);

    return (
        <>
            {showPricing && <SlidePanel
                isOpen={showPricing}
                width={"100vw"}
                showHeader={true}
                contentClassName="bg-white"
                from="right"
                zIndex={4001}>
                <Pricing
                    hidePricingOverlay={hidePricingOverlay}
                    id="pricing-overlay"
                    damage={dmg}
                    pricingTypes={pricingTypesList}
                    handlePricingUpdate={handlePricingUpdate}
                    cancelPricingOverlay={cancelPricingOverlay}
                />
            </SlidePanel>}
            <Button id={'add-suggested-' + suggestedDamage.artCodes.damageKey}
                    onClick={handleAddSuggestedDamage}
                    disabled={isDisabled}
                    className={"mx-1 my-0"}
                    color="secondary">
                <i className={"icon prism-icon-checkmark p-1 " + (isDisabled ? 'text-light-gray' : 'text-white')}/>
            </Button>
        </>
    );
};

const matchDispatchToProps = {
    addDamage,
    addSuggestedDamage,
    addSuggestedDamageImage,
    showDamageSuccessMessage,
    clearSelectedFlatCarPanel
};

function mapStateToProps({account, condition, consignment, unit, workOrderInfo}) {
    return {account, condition, consignment, unit, workOrderInfo};
}

export default connect(mapStateToProps, matchDispatchToProps)(AddSuggestedDamageButton);
